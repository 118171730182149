
    let metrics = {};
const socket = new WebSocket('wss://event-service.idcrealestate.com/ws/track-event');
    document.addEventListener('DOMContentLoaded', () => {
    const timing = window.performance.timing;

    setTimeout(()=>{
        metrics = {
        navigationStart: formatAbsoluteTime(timing.navigationStart),
        pageLoadTime: validateAndFormatTime(timing.loadEventEnd, timing.navigationStart),
        timeToFirstByte: validateAndFormatTime(timing.responseStart, timing.navigationStart),
    };
    },10);

    
});

// Utility to format absolute timestamps
function formatAbsoluteTime(ms) {
    if (!ms || ms < 0) return "Invalid timestamp";
    const date = new Date(ms);
    return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
}

// Utility to calculate time differences
function validateAndFormatTime(endTime, startTime) {
    if (!endTime || !startTime || endTime <= startTime) return "0.00 seconds"; // Handle invalid or missing values
    const difference = (endTime - startTime) / 1000; // Convert to seconds
    return `${difference.toFixed(2)} seconds`;
}
    function getPageName() {
        const url = window.location.href.toLowerCase();
        if (url.includes('/property-search-map')) return "PropertyMapSearch";
        if (document.querySelector('.detailpage')) return "PropertyDetail";
        if (url.includes('/real-estate-agent')) return "AgentDetail";
        if (url.includes('/community')) return "CommunityDetail";
        if (url.includes('/neighborhood')) return "NeighborhoodDetail";
        if (url.includes('/real-estate-offices')) return "OfficeResult";
        if (url.includes('/real-estate-office')) return "OfficeDetail";
        if (url.includes('/real-estate-agents')) return "AgentResult";
        if (url.includes('/real-estate-agent')) return "AgentDetail";
        if (url.includes('/market-trends')) return "MarketTrends";
        return "HomePage";
    }
    function GetFormJson() {
        var formData = {}; 
        formData["PAGENAME"]=getPageName();
        const clientElement = document.querySelector('[data-pcode]');
        const dataPcode = clientElement ? clientElement.getAttribute('data-pcode') : "IBP";
        formData["CLIENT"]=dataPcode;
        formData["WEBVITALS"]=metrics;
        return formData;
    }
//send data using websocket
    function sendEventData(eventName, additionalData = {}) {
        const hostUrl = window.location.host.toString().toLowerCase();
        const rawUrl = window.location.href.toString().toLowerCase();
        const queryParams = new URLSearchParams(window.location.search).toString().toLowerCase();
        const clientElement = document.querySelector('[data-pcode]');
        const dataPcode = clientElement ? clientElement.getAttribute('data-pcode') : "IBP";
        const eventData = {
            eventname: eventName,
            eventlabel: "PageLoad",
            info: {
                host: hostUrl,
                rawUrl: rawUrl,
                queryParams: queryParams,
                additionaljson :GetFormJson(),
            },
            client:dataPcode
        };
        if (socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify(eventData));
            //console.log('Data sented:', eventData);
        } else {
            console.warn('WSS_NO_CONN');
        }
    }

    // click handler
    function ClickHandler(event) {
        const eventName = event.target.getAttribute('aria-label') || 'button-click-test';
        sendEventData(eventName.toString().toLowerCase());
    }

    // pageload handler
    function handlePageLoad() {
        sendEventData('PageLoad');
    }

    // socket connection
    socket.onopen = () => {
        //console.log("Websocket connection established");
        handlePageLoad(); // Send page-load event when WebSocket opens
    };

    socket.onmessage = (e) => {
        //console.log("Received from server:", e.data);
    };

    socket.onclose = () => {
        //console.log("Websocket connection closed");
    };

socket.onerror = (error) => {
    console.error("Websocket error:", error);
    };

    // attaching the event listners
    //document.addEventListener('load', handlePageLoad);
    //document.addEventListener('click', ClickHandler);


